.cardContent {
    display: flex;
    flex-direction: column;
}
.trackersTable {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.graphs {
    display: flex;
    flex-direction: column;
}

.ncuGraphs {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: -5%;
}

.windGraphs{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30%;
}

.windStyle {
    width: 15%;
    margin-top: 5%;
    margin-left: -2%;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1544px){
    .graphs {
        flex-direction: column;
    }

    .windGraphs {
        flex-direction: row;
        column-gap: 50px;
        width: 100%;
    }
}

@media screen and (max-width: 1392px){
    .trackersTable {
        grid-template-columns: repeat(2, 1fr);
    }
    .ncuGraphs {
        flex-direction: column;
    }
    .windStyle {
        width: 100%;
        flex-direction: row;
        justify-content: center;
    }
}

@media screen and (max-width: 768px){
    .trackersTable {
        grid-template-columns: 1fr;
    }
}