.containerStyle {
    position: relative;
    bottom: -8px;
    top: 6px;
    left: -136px;
    width: 300px;
    background-color: var(--background-color);
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
    padding: 10px;
    font-size: 14px;
    z-index: 100;
    border-radius: 5px;
    border: 1px solid black;
}

.containerStyle::before {
    content: "";
    position: absolute;
    border-style: solid;
    border-width: 10px;
    border-color: transparent transparent white transparent;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
}