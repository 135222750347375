.pulse-effect {
    animation: pulse-animation 0.6s ease-in-out; /* A animação dura 0.6s */
}

@keyframes pulse-animation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3); /* Aumenta o tamanho em 30% */
    }
    100% {
        transform: scale(1); /* Volta ao tamanho original */
    }
}