.container {
    margin-left: -10px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 30px;
    max-width: 120% !important;
    width: 100% !important;
}

.containerNotificacoes {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    width: 50% !important;
}

.permissoes {
    width: 300px;
    margin-top: 30px !important;
}

@media (max-width: 840px) {
    .container {
        flex-direction: column !important;
        align-items: center !important;
    }

    .containerNotificacoes {
        display: flex !important;
        justify-content: center !important;
        width: 100% !important;
    }

    .permissoes {
        margin-left: 8% !important;
    }
}