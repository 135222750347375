.containerHeader {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 50px;
    row-gap: 20px;
}

@media screen and (max-width: 1350px) {
    .containerHeader {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 1200px) {
    .containerHeader {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 900px){
    .containerHeader {
        display: flex;
        flex-direction: column;
    }
}