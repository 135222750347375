.titleReport {
    width: 300px;
}

.filter {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    width: 17vw;
}

.filterButtons {
    display: flex;
    flex-direction: row;
}

.dataFilter {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 10px;
    gap: 20px;
}

.tableFilter {
    width: 80%;
    margin-left: 4%;
    margin-top: -3%;
}

.formControl {
    min-width: 200px !important;
}

.gridPage {
    display: flex;
}

@media screen and (max-width: 1200px){
    .filter {
        flex-direction: column;
    }
    .dataFilter {
        grid-template-columns: repeat(3, 1fr);
    }
    .formControl {
        min-width: 150px !important;
    }
}

@media screen and (max-width: 1080px){
    .filter {
        flex-direction: column;
    }
    .dataFilter {
        grid-template-columns: repeat(2, 0.5fr);
        justify-content: center;
    }
}

@media screen and (max-width: 900px){
    .filter {
        margin-left: 11px;
    }

    .dataFilter {
        display: flex;
        justify-content: center;
        flex-direction: column;
        /* align-items: center; */
    }

    .gridFilter {
        /* margin-left: 6.5% !important; */
    }

    .tableFilter {
        /* width: 50%; */
        width: 100%;
    }
    .gridPage {
        flex-direction: column;
    }
}