body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.critial-status.MuiSvgIcon-root > path {
  animation-name: backgroundColorPalette;
  animation-duration: 750ms;
  animation-direction: alternate;
  animation-timing-function: linear;
  /*animation-timing-function: ease-in-out;*/
  animation-iteration-count: infinite;
  /*animation-play-state: running;*/
}

@keyframes backgroundColorPalette {
  /*0% {*/
  /*  background: #ffffff;*/
  /*}*/
  /*25% {*/
  /*  background: #ffbaba;*/
  /*}*/
  0% {
    background: #ff7b7b;
  }
  /*75% {*/
  /*  background: #ff5252;*/
  /*}*/
  50% {
    background: #ff5252;
  }
  100% {
    background: #ff0000;
  }

}

.leaflet-container {
  width: 100%;
  height: 82vh;
  /*border-radius: 25px */
}
